@media (max-width: 1600px) {
  .footer__right-wrapper {
    text-align: right;
  }
}

@media (max-width: 1000px) {
  .footer .footer-inner-wrapper {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .footer__left-wrapper {
    flex-basis: 100%;
    width: 100%;

    flex-direction: column;

    .left-wrapper__phone {
      margin: 10px 0;
    }
  }
  .footer__right-wrapper {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .footer {
    max-height: 180px;
  }
}

@media (max-width: 550px) {
  .footer .footer-text {
    font-size: 12px;
    line-height: 16px;
  }

}
