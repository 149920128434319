@media (max-width: 1270px) {
  .section-pictures-on-background {
    min-height: 530px;
  }
}

@media (max-width: 1200px) {
  .section-pictures-on-background {
    min-height: 470px;
  }
}

@media (max-width: 1100px) {
  .section-pictures-on-background {
    min-height: unset;
    padding: 30px 0;
    margin: 0;
    background-size: cover !important;
  }

  .section-pictures-on-background__img-wrapper:nth-child(1), .section-pictures-on-background__img-wrapper:nth-child(3) {
    margin: unset;
  }

  .carousel .slider-wrapper.axis-horizontal .slider {
    .slide {

      display: flex;
      align-items: center;
      justify-content: center;

      .section-pictures-on-background__img-wrapper {
        flex-basis: unset;
        width: 100%;

        img {
          width: 30%;
        }
      }
    }
  }

  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
    width: 35px;
    height: 35px;
    top: 50% !important;
    transform: translateY(-50%);
    border-radius: 50%;
    border: 2px solid white !important;
  }

  .carousel.carousel-slider .control-arrow:before {
    width: 15px !important;
    height: 2px !important;
    border: none !important;
    position: absolute;
    top: 50%;
    left: 9px !important;
    transform: translate(-50%, -50%);
    background-color: white;
  }

  .carousel.carousel-slider .control-arrow:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg) translate(-50%, -50%);
    left: 42%;
  }

  .carousel.carousel-slider .control-arrow:hover {
    background-color: transparent !important;
  }
  .carousel .control-prev.control-arrow {
    transform: rotate(180deg) translateY(50%);
    left: 50px !important;

    &:after {
      top: 59%;
    }
  }
  .carousel .control-next.control-arrow {
    right: 50px !important;

    &:after {
      top: 56%;

    }
  }
}

@media (max-width: 550px) {
  .carousel .slider-wrapper.axis-horizontal .slider .slide .section-pictures-on-background__img-wrapper img {
    width: 60%;
  }
  .carousel .control-prev.control-arrow {
    left: 20px !important;
  }
  .carousel .control-next.control-arrow {
    right: 20px !important;
  }
}
