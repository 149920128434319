.header {
  display: flex;
  padding: 20px 0;
  max-height: 120px;
  width: 100%;
  height: 100%;

  .header-inner-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
  }

  &__menu {
    max-width: 540px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    list-style-type: none;
    padding: 0;

    .menu-item {
      .menu-link {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        color: black;

        &:hover, &:focus {
          color: #4C74B7;
        }
      }

      .menu-link-active {
        color: #4C74B7;
      }

      .menu-link-button {
        border: none;
        background-color: transparent;
      }
    }
  }
}

.shadow {
  box-shadow: 0 4px 22px rgba(0, 0, 0, .08);
}

@import "media";
