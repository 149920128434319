@media (max-width: 1500px) {
  .section-color-text .left-block, .section-color-text .right-block {
    flex-basis: 50%;
  }
}

@media (max-width: 1366px) {
  .section-color-text .left-block {
    padding-left: 50px;
  }
}

@media (max-width: 1100px) {
  .section-color-text {
    flex-direction: column;
    margin-right: 0;
  }
  .section-color-text .left-block, .section-color-text .right-block {
    padding-left: 50px;
    padding-right: 50px;
  }
  .section-color-text .left-block {
    border-radius: 0;
    width: 100%;
  }
}

@media (max-width: 950px) {
  .products-title {
    padding: 30px 0;
  }
  .section-color-text .left-block__price {
    font-size: 14px;
    line-height: 17px;
  }
  .section-color-text {
    .right-block {
      .right-block__text {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 500px) {
  .section-color-text .left-block, .section-color-text .right-block {
    padding-left: 20px;
    padding-right: 20px;
  }
}
