.list-background-card-item {
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 10px 14px;

  min-height: 105px;
  width: 100%;

  &__title, &__text {
    color: black;
    font-family: 'Open Sans',sans-serif;
    font-size: 16px;
    line-height: 22px;
  }

  &__title {
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__text {
    margin: 0;
  }
}

.list-background-card-item + .list-background-card-item {
  margin-top: 10px;
}
