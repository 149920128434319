.section-pictures-on-background {
  min-height: 600px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  padding: 32px 133px;
  margin-top: 70px;
  margin-bottom: 50px;

  &__img-wrapper {
    flex-basis: 33%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(1), &:nth-child(3) {
      margin-top: -200px;
    }

    &:nth-child(4), &:nth-child(6) {
      margin-top: -20px;
    }

    &:nth-child(5) {
      margin-bottom: -130px;
    }
  }

  &__img {
    width: 50%;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.2);
  }
}

.video-erp {
  margin: 50px auto;
  width: 60% !important;
}

.video-erp-mad {
  margin: 50px auto;
  height: 500px;
}


@import "media";
