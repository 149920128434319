@media (max-width: 950px) {
  .contact-card__text-wrapper {
    grid-template-columns: 1fr;

    .contact-point__text {
      font-size: 14px;
      line-height: 19px;
    }
  }
}

@media (max-width: 750px) {
  .contacts-content__title {
    margin-top: 30px;
  }
  .contact-card {
    padding: 20px 0;
  }
  .contact-card__text-wrapper {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;

    .contact-point-email {
      .contact-point__img {
        margin-right: 10px;
      }
    }
  }
}
