.service-card {
  padding: 50px 0;

  &__title {
    margin-bottom: 24px;
    width: 50%;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: space-between;

    .text-wrapper__description, .text-wrapper__points {
      flex-basis: 45%;
      margin: 0;
    }

    .text-wrapper__points {
      list-style-type: disc;

      li + li {
        margin-top: 15px;
      }
    }
  }
}

.service-card + .service-card {
  border-top: 3px solid #23BBDD;
}

@import "media";
