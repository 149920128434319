.order-input {
  display: grid;

  &__label, &__input {
    font-size: 16px;
    line-height: 22px;
    font-family: 'Open Sans', sans-serif;
  }

  &__label {
    font-weight: 600;
    margin-bottom: 8px;
    color: #646464;

    .required-star {
      color: #F04438;
    }
  }

  &__input {
    color: #000;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 10px 24px;
    outline: 1px solid transparent;
    height: 44px;

    &::placeholder {
      color: #646464;
    }

    &:focus-visible {
      border: 1px solid #4C74B7;
      box-shadow: 1px 2px 7px 1px rgba(76, 116, 183, 0.5);
    }

    &:invalid:not([value='']) {
      border: 1px solid #F04438;
      box-shadow: 1px 2px 7px 1px rgba(240, 68, 56, 0.5);
    }
  }

  &__select {
    padding: 12px 24px 12px 0;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 !important;
}

.ant-select-arrow, .ant-select-clear {
  right: 24px;
}

.ant-select-arrow {
  color: #000;
}

.ant-select-selection-item {
  padding-left: 24px !important;
}

.medium-input {
  grid-column: 1 / span 2;
}

.long-input {
  grid-column: 1 / -1;
}

#wrapper-car-park {
  grid-column: 3;
  grid-row: 1;
}

#wrapper-the-number-of-employees,
#wrapper-own-repair-area,
#wrapper-the-number-of-workers {
  grid-row: 5;
}

@media (max-width: 950px) {
  .medium-input, .long-input,
  #wrapper-car-park,
  #wrapper-the-number-of-employees,
  #wrapper-own-repair-area,
  #wrapper-the-number-of-workers {
    grid-column: 1;
    grid-row: unset;
  }
  #wrapper-car-park {
    grid-row: 3;
  }
}

@media (max-width: 600px) {
  .order-input {
    .order-input__input {
      padding: 10px;
    }
  }
}