#form-order {
  .form-fields {
    .order-input + .order-input {
      margin-top: 12px;
    }

    .checkbox {
      margin: 20px 0;

      .ant-checkbox-inner {
        background-color: #fff;
        border-color: #B132FF;
      }

      .ant-checkbox + span {
        font-size: 12px;
        line-height: 1;
        letter-spacing: -0.015em;
        color: #646464;
      }
    }

    .checkbox-checked {
      .ant-checkbox-inner {
        background-color: #B132FF;
      }
    }
  }

  .form-order-btn {
    width: 100%;

    &:disabled {
      background-color: rgba(#B132FF, .5);
    }
  }
}

@media (max-height: 720px) {
  #form-order {
    height: 460px;
  }
}

@media (max-height: 575px) {
  #form-order {
    height: 400px;
  }
}