.page-subtitle {
  font-size: 32px;
  line-height: 39px;
  font-weight: 500;
}

.color-white {
  color: white;
}

@import "media";
