.instruction-download-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  &__text {
    padding-left: 10px;
    margin: 0;
    color: white;
    font-size: 16px;
    line-height: 22px;
  }
}

.instruction-download-wrapper {
  margin: 20px 0;
}
