.section-picture-background__cards-wrapper {
  width: 90%;
  height: 100%;
  min-height: 640px;
  counter-reset: number;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
