.contact-card {
  padding: 56px 0 113px;

  &__text-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 45px;
  }
}

.contact-card + .contact-card {
  border-top: 3px solid #23BBDD;
}

@import "media";
