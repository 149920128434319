.btn-call {
  position: fixed;
  z-index: 98;
  bottom: 24px;
  right: 165px;

  background-color: #fff;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 8px;
  border: none;

  display: grid;
  grid-template-columns: 1fr 3fr;
  place-items: center;

  transition: all .3s ease;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  .btn-call_text {
    margin: 0 0 0 5px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }
}

@media (max-width: 1366px) {
  .btn-call {
    right: 50px;
  }
}

@media (max-width: 500px) {
  .btn-call {
    right: 20px;
    grid-template-columns: 1fr;
  }
}
