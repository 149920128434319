.bg-image {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;
  z-index: -1;
}

.top-section {
  padding: 120px 0 70px;

  &__title {
    max-width: 70%;
    width: 100%;
    margin-bottom: 20px;
  }
  
  &__accreditation {
    max-width: 70%;
    width: 100%;
    margin-bottom: 64px;
    font-size: 30px;
    font-family: 'Open Sans', sans-serif;
  }

  &__btn {
    border-radius: 100px;
    background: transparent linear-gradient(98.44deg, #B132FF -24.6%, #4CAEDA 93.48%);
    border: none;
    color: white;
    text-transform: uppercase;

    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 52px;
    font-weight: 600;
    max-width: 220px;
    width: 100%;
    text-align: center;

    transition: all .3s linear;

    &:hover {
      color: white;
      transform: scale(1.03);
    }
  }
}

.middle-section {
  .product-cards-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    padding: 70px 0 48px;
  }

  .competencies-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;

    padding-top: 48px;
    padding-bottom: 48px;

    &__title {
      margin-bottom: 80px;
    }

    .competence-card-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: baseline;
      justify-content: space-around;
    }
  }

  .clients-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;

    padding-top: 48px;
    padding-bottom: 94px;

    &__title {
      margin-bottom: 40px;
    }

    .clients-brands-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
      gap: 80px;
      width: 100%;
    }
  }
}

@import "media";
