@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
  }
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, .5);
  animation-name: appear;
  animation-duration: .3ms;

  .modalDialog {
    width: 100%;
    max-width: 450px;
    max-height: 95vh;
    margin: 0 5px;

    background-color: #fff;
    border-radius: 20px;
    animation-name: slide-in;
    animation-duration: 0.5s;

    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .modalHeader, .modalBody {
      padding: 17px;
      width: 100%;
    }

    .modalHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .modalTitle {
        font-family: 'Montserrat', sans-serif;
        color: #000;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
      }

      .button-modal-close {
        background-color: #F7F7F7;
        border: none;
        border-radius: 8px;
        padding: .25em .5em .5em;
        font-size: 22px;
        line-height: 1;
        font-weight: 900;

        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }

    .modalBody {
      padding-top: 0;
      background-color: #fff;
      text-align: right;

      .rcs-custom-scroll .rcs-inner-handle {
        background-color: rgba(black, .15);
        //border: .5px solid black;
      }

      .modal-btn {
        max-width: 230px;
        width: 100%;
      }
    }
  }

  .modalDialogOut {
    animation-name: slide-out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}

.modal-form-order, .modal-call {
  .modalDialog {
    .modalBody {
      text-align: left;
      background-color: #fff;
    }
  }
}

@media (max-width: 450px) {
  .modal .modalDialog {
    max-width: 80vw;
    max-height: unset;
    height: unset;

    .modalHeader, .modalBody {
      padding: 24px;
    }

    .modalHeader {
      .modalTitle {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .modalBody {
      .modal-btn {
        max-width: 100%;
      }
    }
  }

  .modal-form-order, .modal-call {
    .modalDialog {
      .modalHeader {
        padding-bottom: 0;
      }
    }
  }

  .modal-form-order {
    .modalDialog {
      max-width: 95vw;
    }
  }
}
