@media (max-width: 950px) {
  .products-content__card-wrapper {
    .products-card {
      .products-card__img {
        width: 20%;
      }
    }
  }
}

@media (max-width: 750px) {
  .products-content__card-wrapper {
    .products-card {
      flex-direction: column;
    }

    .products-card__text-wrapper {
      display: flex;
      align-items: flex-start;
    }

    .products-card__text-wrapper .text-wrapper__description {
      margin-top: 0;
      font-size: 14px;
      line-height: 19px;
    }
  }
  .products-content {
    padding: 30px 0 80px;
  }
}

@media (max-width: 550px) {
  .products-content__card-wrapper .products-card {
    padding: 30px 0;
  }
}
