.product-card {
  overflow: hidden;
  flex-basis: 50%;
  min-height: 330px;
  height: 100%;
  position: relative;
  padding: 30px;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  &__inner {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    
    min-height: 260px;
  }
  
  &__title {
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    color: white;
    margin-bottom: 15px;
  }
  
  &__text {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    color: white;
    
    span {
      font-size: 12px;
    }
    
    ul {
      margin: 0;
      
      li + li {
        margin-top: 10px;
      }
    }
  }
  
  .product-card__hovered-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 45px;
    
    transition: all .5s ease;
    
    .alternative-text {
      font-size: 24px;
      line-height: 29px;
      font-weight: 500;
      color: white;
      opacity: 0;
      transition: opacity .5s ease;
    }
    
    .arrow {
      opacity: 0;
      transition: opacity .5s ease;
    }
  }
  
  &:hover, &:focus {
    .product-card__hovered-content {
      z-index: 10;
      opacity: 1;
      
      .alternative-text, .arrow {
        opacity: 1;
      }
    }
  }
}

.product-card.left {
  padding-bottom: 0;
  align-items: flex-end;
  
  .product-card__inner {
    align-self: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.product-card.center {
  flex-direction: column;
  padding: 30px 0;
  
  .product-card__inner {
    min-height: unset;
    align-items: flex-start;
    padding: 0 30px;
    width: 100%;
  }
  
  .product-card__img {
    width: 100%;
  }
}

.product-card.right {
  flex-direction: row-reverse;
  
  .product-card__inner {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.product-card.none {
  padding: 0 45px;
  
  .product-card__title {
    margin-bottom: 0;
  }
}

.product-card.sbm {
  background-color: #4C9ED0;
  
  &:hover, &:focus {
    .product-card__hovered-content {
      background: linear-gradient(113.46deg, #4C9ED0 3.13%, #B53BFF 101.55%);
    }
  }
}

.product-card.erp {
  background-color: #B132FF;
  
  &:hover, &:focus {
    .product-card__hovered-content {
      background: linear-gradient(113.46deg, #B132FF 32.04%, #F0B730 90.55%);
    }
  }
}

.product-card.mad {
  background-color: #78C13B;
  
  &:hover, &:focus {
    .product-card__hovered-content {
      background: linear-gradient(113.46deg, #90D105 32.04%, #F0B730 90.55%);
    }
  }
  
  .product-card__img {
    width: 40%;
  }
}

.product-card.empty {
  background-color: #F0B730;
  
  &:hover, &:focus {
    .product-card__hovered-content {
      background: linear-gradient(113.46deg, #F0B730 31.35%, #F87A03 92.89%);
    }
  }
}

@import "media";
