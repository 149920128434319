.footer {
  display: flex;
  height: 100%;
  width: 100%;
  max-height: 90px;
  padding: 32px 0;
  border-top: 1px solid #C4C4C4;

  .footer-inner-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .footer-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 22px;
    color: black;
  }

  p, a {
    margin: 0;
  }

  &__left-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;

    gap: 20px;

    .left-wrapper__phone {
      transition: all .5s ease;

      &:hover, &:focus {
        color: #4C74B7;
      }
    }

    .left-wrapper__email {
      transition: all .5s ease;
      color: #4C74B7;

      &:hover, &:focus {
        color: black;
      }
    }
  }

  &__right-wrapper {
    .right-wrapper__policy {
      transition: all .5s ease;

      &:hover, &:focus {
        color: #4C74B7;
      }
    }
  }
}

@import "media";
