.list-item {
  padding-left: 0;
  position: relative;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 22px;
}

.yellow {
  &::marker {
    color: #F0B730;
  }
}

.blue {
  &::marker {
    color: #23BBDD;
  }
}

.green {
  &::marker {
    color: #90D105;
  }
}

@import "media";
