@import './assets/fonts/Montserrat/stylesheet.css';
@import './assets/fonts/OpenSans/stylesheet.css';

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body, html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  scroll-behavior: smooth;
  height: unset;
}

body {
  position: relative;
  height: 100%;
  min-height: 100vh;
  min-width: 320px;
  max-width: 1920px;
  overflow-x: hidden;
  margin: 0 auto;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;

  height: 100%;
  min-height: 100vh;
}

a {
  display: inline-block;
  text-decoration: none;
}

a, button {
  cursor: pointer;
}

.container {
  margin: 0 165px;
}

.inner-container.inner-container {
  padding-left: 65px;
  padding-right: 65px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, sans-serif;
  margin: 0;
}

p, span {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.products-title {
  padding: 32px 0 45px;
}

ul {
  margin: 0;
}

.purple-btn {
  font-family: 'Montserrat', sans-serif;
  background: #B132FF;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  color: #FFFFFF;
  height: 48px;
  border: none;

  transition: all .3s ease-in-out;

  &:hover, &:focus {
    background-color: #840ECC;
  }
}

@media (max-width: 1366px) {
  .container {
    margin: 0 50px;
  }
}

@media (max-width: 1100px) {
  .products-title {
    padding: 30px 0;
  }
}

@media (max-width: 700px) {
  .inner-container.inner-container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 500px) {
  .container {
    margin: 0 20px;
  }
}
