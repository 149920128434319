.card-with-icon {
  max-width: 350px;
  min-height: 300px;
  width: 100%;
  height: 100%;

  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin: 20px;

  &__text {
    padding: 16px 13px;
    margin: 0;
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;

    width: inherit;
    min-height: 160px;
  }
}
